import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../../layouts/Layout/Layout'
import './AfterSales.scss'
import { RecentArticleTypes } from '../../layouts/ArticleLayout/ArticleLayout'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import {
  addFormValidation,
  setIsIframe,
} from '../../components/Validators/InputValidations'
import { removeAfterSalesSE, removeDefaultSE } from '../../services/snapengage'
import { toKebabCase } from '../../services/caseTransform'
import ContactUsLayout from '../../layouts/ContactUsLayout/ContactUsLayout'

const ArticleLayout = loadable(() =>
  import('../../layouts/ArticleLayout/ArticleLayout'),
)

const PopupModal = loadable(() =>
  import('../../components/PopupModal/PopupModal'),
)

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SnapEngage: any
  }
}

type AfterSalesPropTypes = {
  data: {
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
    allShowroom: {
      nodes: {
        showRoomTitle: string
      }[]
    }
    homePageSeo: {
      seoSalesDescription: string
      seoSalesTitle: string
    }
  }
}

const AfterSales = ({
  data: {
    recentArticles: { nodes: recentArticles },
    allShowroom: { nodes: allShowroom },
    homePageSeo: { seoSalesDescription, seoSalesTitle },
  },
}: AfterSalesPropTypes): ReactElement => {
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)
  const [displayForm, setDisplayForm] = useState(true)

  const openChatSupport = useCallback((e) => {
    e.preventDefault()
    if (window.SnapEngage) {
      window.SnapEngage.openProactiveChat(true, true)
    }
  }, [])

  useEffect(() => {
    if (!displayForm) {
      setDisplayForm(true)
    }
  }, [displayForm])

  const handleFormReady = useCallback(() => {
    setIsIframe(true)
    addFormValidation('after-sales-form', [
      {
        elementId: 'firstname-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'lastname-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'numericOnly',
      },
      {
        elementId: 'address-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'message-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'mobilephone-5cbc9f47-2e91-42b8-8d06-92828939750e',
        validationType: 'numericOnly',
      },
    ])
  }, [])

  const handleSubmit = useCallback(() => {
    setIsSuccessShown(true)
  }, [])

  const handleSuccessClose = useCallback(() => {
    setIsSuccessShown(false)
    setDisplayForm(false)
  }, [])

  useEffect(() => {
    removeDefaultSE()

    return (): void => {
      removeAfterSalesSE()
    }
  }, [])

  const initialShowroom = allShowroom[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  const contactUsNavigation = CONTACT_US_NAVIGATION.map((item) => {
    return {
      url: item?.url,
      label: item.label,
      items: item.items?.map((val) => {
        return {
          url:
            val.url === 'contact-us/showroom'
              ? `contact-us/showroom/${showroomUrl}`
              : val.url,
          label: val.label,
        }
      }),
    }
  })

  return (
    <Layout>
      <Seo
        slug="contact-us/after-sales"
        ogMetaData={{
          title:
            seoSalesTitle !== 'nc'
              ? seoSalesTitle
              : 'Contact Us | Sales Inquiry',
          description:
            seoSalesDescription !== 'nc'
              ? seoSalesDescription
              : 'Already have an Avida project in mind? Provide the necessary information below for a simple and seamless homebuying experience.',
          image: '',
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/contact-us/after-sales/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, contact us, after sales inquiry',
        }}
        title={
          seoSalesTitle !== 'nc' ? seoSalesTitle : 'Contact Us | Sales Inquiry'
        }
      />
      <ContactUsLayout className="after-sales">
        <ArticleLayout
          title="Contact Us"
          asideNavItems={contactUsNavigation}
          asideArticleTitle="News & Events"
          breadcrumbs={[
            {
              label: 'After Sales',
            },
          ]}
          recentArticles={recentArticles}
        >
          {seoSalesTitle !== 'nc' && (
            <h1 style={{ display: 'none' }}>{seoSalesTitle}</h1>
          )}
          <h2 className="after-sales-title">After Sales</h2>
          <div className="after-sales-chat-link-container">
            <h2 className="after-sales-chat-link-title">
              We&apos;re here to help you.
            </h2>
            <p>
              Click on the icon on the lower right to chat with our customer
              service representatives for concerns regarding your property
              purchase with Avida Land.
            </p>
          </div>
          <div className="after-sales-contact-channels-container">
            <div className="after-sales-contact-channels-title">
              You can also reach us through other channels:
            </div>
            <ul className="after-sales-contact-channels">
              <li>
                <strong>Email : </strong>
                residential.customers@ayalaland.com.ph
              </li>
              <li>
                <strong>Customer Service Hotline : </strong>(+632) 8848-5000
              </li>
            </ul>
          </div>
        </ArticleLayout>
      </ContactUsLayout>
      <PopupModal
        isShowing={isSuccessShown}
        onClose={handleSuccessClose}
        header="Thank you for your inquiry"
        body="Please expect our Customer Care representative to get in touch with you as soon as possible."
        contactList={[
          'Sales Hotline: (+632) 848-5200',
          'Office Trunk Line: (+632) 988-2111',
        ]}
      />
    </Layout>
  )
}

export default AfterSales

export const pageQuery = graphql`
  query {
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
    allShowroom(filter: { type: { eq: "" } }) {
      nodes {
        showRoomTitle
      }
    }
    homePageSeo {
      seoSalesDescription
      seoSalesTitle
    }
  }
`
